import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import { Container, Row, Col } from 'react-bootstrap';
import AOS from 'aos';
import './index.css';

export default class Associates extends Component {
    componentDidMount(){
        AOS.init({
          duration : 1500
        });
      }

    render(){
        return (
            <div className="AssociatesClass" style={{ paddingTop: '8rem' }}>
                <Container>
                    <h2 data-aos="zoom-in" className='AssociateTitle'>OUR ASSOCIATES</h2>
                    <Row className='AwardsRow'>
                        <Col className="p-2 col-lg-4 col-md-6 col-sm-6 col-12"><Card data-aos="flip-left" className="CardSection overflow-auto">
                            <Card.Img variant="top" src="image/Associates3.webp" className="CardImage CardImage1" />
                            <Card.Body  className='CardText'>
                                <Card.Title>AideExpert Advisory</Card.Title>
                                <Card.Text >
                                AideExpert Advisory Provides Digital Business Strategy for Midsize Businesses. Supercharge your startup with the Startup Supercharger programme. It also helps to develop exceptional Digital Roadmaps with the virtual CIO services and also helps to deliver  various  product with virtual CTO services.
                                <br/>Aideexpertadvisory is a Management Consulting company and has headquarters in Pune,maharashtra. It was founded in 2016. Aideexpertadvisory.com specialises in management consulting. 
                                    </Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col className="p-2 col-lg-4 col-md-6 col-sm-6 col-12">
                            <Card data-aos="flip-left"  className="CardSection overflow-auto">
                                <Card.Img variant="top" src="image/Associates4.png" className="CardImage CardImage2" />
                                <Card.Body className='CardText'>
                                    <Card.Title>Shyena Tech Yarns<br />  ( A Data Science Company) </Card.Title>
                                    <Card.Text>
                                        Shyena Tech Yarns Pvt Ltd is a Data Science and Digital Transformation company, head quartered in Pune and offices in UK and USA. The client base is spread across UK, USA, Singapore, Netherlands and India. We are one of the only few companies with equally good expertise in AI & Platform development.<br /><br />
                                        Shyena Tech Yarns have solved some of the very complex business problems using AI across industry verticals. Some of the industry vertical include Banking, Insurance, Healthcare, Manufacturing, Legal, Ecommerce, Logistics & ITES.<br /><br />
                                        Our key expertise are Machine Learning, Deep Learning, Computer Vision, Natural Language Processing, Recommender System, Big Data, Platform Development, DevOps and Cloud Technologies.<br /><br />
                                        Shyena also have some value added AI solutions like NLP Based Conversational Intelligence Suite, Deep Learning Based Document Data Extractor, Computer Vision based Video Analytics, Product Recommender System, Machine Learning Based Predictive Maintenance.<br /><br />
                                        Apart from AI solutions development, we have exceptional expertise in AI model deployment & Scaling. We also do end to end product engineering. It includes development and maintenance of the product i.e. the complete product life cycle management.<br /><br />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-2 col-lg-4 col-md-6 col-sm-6 col-12">
                            <Card  data-aos="flip-left" className="CardSection overflow-auto">
                                <Card.Img variant="top" src="image/Associates1.jpg" className="CardImage" />
                                <Card.Body className='CardText'>
                                    <Card.Title>Amazure Technologies Private Ltd</Card.Title>
                                    <Card.Text>
                                        Amazure offers solution & consulting services for IT & digital transformation strategic planning & execution .<br /><br />
                                        Today’s biggest challenge / complexity for leadership working on digital transformation strategy ,  is appropriate Visibility & data of various operations ( OT / IIOT / IT) , supply chain management , production planning , process engineering , safety & quality management & manufacturing excellence for digital transformation.<br /><br />
                                        Amazure offers  interesting solution offerings which shall be highly helpful to get appropriate visibility & automation with real time data across all your application & infrastructure performance & scope of optimization along with security visibility. As well Big data analytics which shall also be useful to identify multiple use cases for your operation efficiency & digital transformation for various business functions.<br /><br />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    {/* </Row>
                    <Row> */}
                        <Col className="p-2 col-lg-4 col-md-6 col-sm-6 col-12">
                            <Card data-aos="flip-left" className="CardSection overflow-auto">
                                <Card.Img variant="top" src="image/Associates2.png" className="CardImage CardImage2" />
                                <Card.Body className='CardText'>
                                    <Card.Title>Mobillor</Card.Title>
                                    <Card.Text>
                                        Manufacturing and Industry 4.0 to Manufacturing and Distribution companies.<br />
                                        Mobillor is  listed by CIO Insider Magazine among  "10 Most Recommended Digital Transformation Provider - 2021". We are also part of NASSCOM Smart Manufactuing Competency Center which include delegenty selected few of indias Smart Manufacturing and Industry 4.0 solution providing companies.<br /><br />
                                        At Mobillor we work with Manufacturing companies like you in Enabling, Building and Deploying IT applications to support your journey of Digital Transformation towards the goal of Smart Manufacturing and Industry 4.0.<br />
                                        Today we are engaged with more then 50 Companies who are using our Products and Solutions. Some of our prominent customers are – Emerson, Marico, Samsonite, MyLAN, TVS, TI Cycles, Centum, SynPack etc.<br /><br />
                                        We have following key solutions as part of our offerings; all these Products and Solutions are integrated with the core ERP and Enterprise Applications of the customers. We have possibilities to integrate with ERP Systems like SAP ECC, SAP S/4 HANA, Infor, Oracle EBS, Epicor, SAGE, MS Dynamics etc.<br />
                                        Our Products and Solutions are:<br />
                                        -        Digital Supplier<br />
                                        -        Digital Warehouse<br />
                                        -        Digital Manufacturing<br />
                                        -        Digital Service<br />
                                        -        Digital Distribution<br /><br />
                                        All the above mentions Products and Solutions are an excellent convergence of various Digit Technologies important for Smart Manufacturing and Industry 4.0<br />
                                        -        Enterprise Application Integrations: All solutions integrate with the core ERP Systems such as SAP, Infor, Oracle, Microsoft Dynamics, SAGE, Epicor etc. Also support integration with third party applications<br />
                                        -        Device Integrations: Be it Machines or IoT, our solutions speak with all of them. We have comprehensive frameworks available to effectively collaborate your device data with business applications.<br />
                                        -        Traceability: You can choose to follow QR Code/ Bar Code or RFID to enable traceability through out your business cycle, we support all of these.<br />
                                        -        Deployment: Its what you choose, you can deploy us on your favourite Cloud like AWS or Azure or any other else you can deploy us On Premise<br />
                                        -        Microservices Approach: Choose to use what you need; we don’t follow monolithic approach where you are forced to buy entire suite even if you need only one module.<br /><br />
                                        Let us be part of your Digital Transformation Journey.<br />

                                        We can make it SAFE (Simple, Affordable, Faster and Executable)
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col className="p-2 col-lg-4 col-md-6 col-sm-6 col-12"><Card data-aos="flip-left" className="CardSection overflow-auto">
                            <Card.Img variant="top" src="image/RubiscapeLogo.png" className="CardImage CardImage1" />
                            <Card.Body className='CardText'>
                                <Card.Title>Rubiscape</Card.Title>
                                <Card.Text >
                                    As economies are reviving, demand for digitalization and automation has accelerated; as everyone wants to increase productivity, automation, flexibility & agility.<br />
                                    Data is rapidly evolving the way businesses think, operate, and create an impact. Data is the soul of business transformation!<br />
                                    Rubiscape® a versatile Low-Code, No-Code, Pro-Code India’s 1st unified Data Science Platform, empowering forward-thinking enterprises and people to Be Data-Smart.<br />
                                    Rubiscape® is empanelled with Govt of India’s GeM Portal and has won many awards & accolades - Aegis Graham Bell Award, Atmanirbhar Bharat Award, CII IP Innovation Award, IEEE Award.<br /><br />
                                    Rubiscape is designed with a unique value proposition – Technology + Agility + Literacy – for solving the complex data problems:<br /><br />
                                    Reliable communication and information flow<br />
                                    Seamless Integration with ERP, HRMS and Project Management Systems<br />
                                    Expense Management<br />
                                    Asset Failure & Maintenance Analytics<br />
                                    HR and Attrition Analytics<br />
                                    Procurement Savings<br />
                                    Sales Forecasting<br />
                                    Inventory Optimization<br />
                                    IoT/Edge Analytics<br />
                                    Social & Text Analytics<br />
                                    Predict Market Opportunities<br />
                                    Image, Audio & Video Analytics<br />
                                    GPS-Map & Location Intelligence<br />
                                    Process Optimization<br />
                                    Risk & Fraud Prevention<br />
                                    Warranty Analytics<br />
                                    Budgeting and Cash Flow Analytics<br />
                                    Supply Chain Analytics<br />
                                    Customer Feedback Analysis<br />
                                    Energy Analytics<br />
                                    Contractor Performance Optimization<br />
                                    Finance Management<br />
                                    Material Management<br />
                                    Reducing Risk and Increasing Safety<br />
                                    Improve paper-based reporting into dynamic Dashboards<br /><br />
                                    Rubiscape® partners with leading enterprises and institutions to help them set up Centres of Excellence (COE) and Innovation Incubation Centres.<br />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                        <Col className="p-2 col-lg-4 col-md-6 col-sm-6 col-12"><Card data-aos="flip-left"  className="CardSection overflow-auto displayCard">
                            <Card.Img variant="top" src="" className="CardImage CardImage1" />
                            <Card.Body className='CardText'>
                                <Card.Title></Card.Title>
                                <Card.Text >
                        </Card.Text>
                            </Card.Body>
                        </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    };
}



