import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import { Nav, Container } from 'react-bootstrap';
import './index.css';


function Navigationbar() {
  return (
    <>
      <Navbar fixed="top" expand="lg" collapseOnSelect>
        <Container>
          <Navbar.Brand href="/"><img src="../../image/LogoRajeevKhade.png" width="90" height="90" alt="Brand Logo"/></Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            {/* me-auto, ms-auto, mx-auto */}
            <Nav className="ms-auto">
              <Nav.Link href="/">Home</Nav.Link>
              <Nav.Link href="/about">About Rajeev</Nav.Link>
              <Nav.Link href="/services">Services</Nav.Link>
              <Nav.Link href="/associates">Our Associates</Nav.Link>
              <Nav.Link href="/blogs">Blog</Nav.Link>
              <Nav.Link href="/testimonials">Testimonials</Nav.Link>
              <Nav.Link href="/gallery">Gallery</Nav.Link>
              <Nav.Link href="/contact">Contact</Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default Navigationbar;